import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { FORM_VALID, maxLength, minLength, required } from '@ds/components/Form'

import type {
  FormValues,
  InputText,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'

import translations from './generateBacsForm.translations'

export const BACS_NUMBER_LENGTH = 8
export const SORT_CODE_LENGTH = 6

export const generateBacsForm = (): Array<InputText> => {
  const i18n = useI18n()

  return [
    {
      id: 'accountNumber',
      cols: 3,
      type: 'text',
      label: i18n(translations.accountNumber),
      description: i18n(translations.accountNumberDescription),
      value: '',
      validation: [
        function requiredAccountNumber(
          accountNumber: string,
          form: FormValues,
        ) {
          return form?.skip
            ? FORM_VALID
            : required(i18n(translations.required))(accountNumber, form, {})
        },
        function accountNumberMaxLength(
          accountNumber: string,
          form: FormValues,
        ) {
          return form?.skip
            ? FORM_VALID
            : maxLength(
                BACS_NUMBER_LENGTH,
                i18n(translations.accountNumberError, {
                  length: BACS_NUMBER_LENGTH,
                }),
              )(accountNumber, form, {})
        },
        function accountNumberMinLength(
          accountNumber: string,
          form: FormValues,
        ) {
          return form?.skip
            ? FORM_VALID
            : minLength(
                BACS_NUMBER_LENGTH,
                i18n(translations.accountNumberError, {
                  length: BACS_NUMBER_LENGTH,
                }),
              )(accountNumber, form, {})
        },
      ],
      extraCss: ['mb-8'],
    },
    {
      id: 'sortCode',
      type: 'text',
      cols: 3,
      label: i18n(translations.sortCode),
      description: i18n(translations.sortCodeDescription),
      value: '',
      validation: [
        function requiredSortCode(sortCode: string, form: FormValues) {
          return form?.skip
            ? FORM_VALID
            : required(i18n(translations.required))(sortCode, form, {})
        },
        function sortCodeMaxLength(sortCode: string, form: FormValues) {
          return form?.skip
            ? FORM_VALID
            : maxLength(
                SORT_CODE_LENGTH,
                i18n(translations.sortCodeError, {
                  length: SORT_CODE_LENGTH,
                }),
              )(sortCode, form, {})
        },
        function sortCodeMinLength(sortCode: string, form: FormValues) {
          return form?.skip
            ? FORM_VALID
            : minLength(
                SORT_CODE_LENGTH,
                i18n(translations.sortCodeError, {
                  length: SORT_CODE_LENGTH,
                }),
              )(sortCode, form, {})
        },
      ],
    },
  ]
}
