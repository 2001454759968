import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { FORM_VALID } from '@ds/components/Form'
import type { InputValue } from '@ds/types/InputValue'
import {
  electronicFormatIBAN,
  friendlyFormatIBAN,
  isValidIBAN,
} from 'ibantools'

import type {
  FormValues,
  InputText,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'

import translations from './generateIbanForm.translations'

export const generateIbanForm = (): Array<InputText> => {
  const i18n = useI18n()
  const errorIban = i18n(translations.formIbanError)

  const formConfig: InputText[] = [
    {
      id: 'iban',
      cols: 6,
      type: 'text',
      label: i18n(translations.formIban),
      description: i18n(translations.formIbanDescription),
      value: '',
      validation: [
        function validateIban(iban: string, form: FormValues) {
          const formattedIban = electronicFormatIBAN(iban.toUpperCase()) || ''

          return isValidIBAN(formattedIban) || form.skip
            ? FORM_VALID
            : errorIban
        },
      ],
      formatter: (inputEvent: InputValue) => {
        if (typeof inputEvent === 'string') {
          return friendlyFormatIBAN(inputEvent) || ''
        }

        return inputEvent
      },
    },
  ]

  return formConfig
}
