export default {
  accountNumberError: {
    id: 'buyback_merchant_update_bacs_account_number_error',
    defaultMessage: 'Your bank account number must be {length} digits long.',
  },
  accountNumber: {
    id: 'buyback_merchant_update_bacs_account_number',
    defaultMessage: 'Bank account number',
  },
  accountNumberDescription: {
    id: 'buyback_merchant_update_bacs_account_number_description',
    defaultMessage: 'E.g. 40308669',
  },
  sortCodeError: {
    id: 'buyback_merchant_update_bacs_sorting_code_error',
    defaultMessage: 'Your sorting code must be {length} digits long.',
  },
  sortCode: {
    id: 'buyback_merchant_update_bacs_sort_code_label',
    defaultMessage: 'Sort Code',
  },
  sortCodeDescription: {
    id: 'buyback_merchant_update_bacs_sort_code_description',
    defaultMessage: 'E.g. 560036',
  },
  required: {
    id: 'buyback_shared_required_field',
    defaultMessage: 'This field is required',
  },
  formSortCodeError: {
    id: 'buyback_bank_form_sort_code_error',
    defaultMessage: 'Error in Sort Code',
  },
  formAccountNumberError: {
    id: 'buyback_bank_form_account_number_error',
    defaultMessage: 'Error in Account Number',
  },
  formRoutingNumberError: {
    id: 'buyback_bank_form_routing_number_error',
    defaultMessage: 'Error in Routing Number',
  },
}
